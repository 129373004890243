<template>
<!--  注册科室-->
<div class="departmentsPlatform">
  <a-spin :indicator="indicator" :spinning="spinning">
  <div class="search_Info">
    <div>
      科室名称:  &nbsp;<a-input v-model="searchName" placeholder="输入科室名称搜索" style="width: 180px" size="default" @keyup.enter="searchEnter"/>&nbsp;&nbsp;&nbsp;
      <a-button style="margin-left: 10px" type="primary" @click="searchOk" >查询</a-button>
      <a-button style="margin-left: 10px"  @click="searchReset">重置</a-button></div>
  </div>
  <div class="InfoTable">
    <div class="table_Info">
      <a-button type="primary" @click="addBtn">新增</a-button>
      <a-table :columns="columns" style="min-height: 500px" :data-source="InfoTable"  rowKey="id"   :pagination="false"
      >
        <span slot="operate" slot-scope="text,item">
          <a  style="margin-left: 10px" @click="addSub(item.id)"> 新增</a>
          <a style="margin-left: 10px" @click="editBtn(item)"> 修改</a>
          <a-popconfirm
              title="确定删除?"
              ok-text="是"
              cancel-text="否"
              @confirm="delOffices(item.id)"
              @cancel="cancel"
              style="margin-left: 10px"
          >
            <a >删除</a>
            </a-popconfirm>
        </span>
        <a-table  :pagination="false"
                  :columns="columns"
                  :showHeader="false"
                  slot="expandedRowRender" slot-scope="row"
                  :dataSource="row.zoffices" :rowKey="item => item.id"
                  :locale="{emptyText: '该类别 暂无节点'}"
        >
          <span slot="operate" slot-scope="item">
          <a style="margin-right: 10px" @click="editSub(item)">修改</a>
          <a-popconfirm
              title="确定删除?"
              ok-text="是"
              cancel-text="否"
              @confirm="delSubOffices(item.id)"
              @cancel="cancel"
          >
            <a >删除</a>
            </a-popconfirm>
      </span>
        </a-table>

      </a-table>
      <div  style="display: flex;justify-content: flex-end;right: 35px;margin-top: 15px;">
        <a-pagination v-model="pagination.current"
                      :total="pagination.total"
                      @change="paginationChange" />
      </div>
    </div>
  </div>

    <a-modal v-model="addShow" title="新增" @ok="addOk" >
      <a-form-model :model="addForm" :label-col="labelCol" :rules="rules" :wrapper-col="wrapperCol" ref="add">
        <a-form-model-item prop="title" label="科室名称">
          <a-input v-model="addForm.title" />
        </a-form-model-item>
        <a-form-model-item  label="科室介绍">
          <a-input v-model="addForm.intro" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal v-model="editShow" title="修改" @ok="editOk" >
      <a-form-model :model="editForm" :label-col="labelCol" :rules="rules" :wrapper-col="wrapperCol" ref="edit">
        <a-form-model-item prop="title" label="科室名称">
          <a-input v-model="editForm.title" />
        </a-form-model-item>
        <a-form-model-item prop="intro" label="科室介绍">
          <a-input v-model="editForm.intro" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-spin>
</div>
</template>

<script>
import {
  delLoginDepartment,
  getLoginDepartment,
  postLoginDepartment,
  putLoginDepartment
} from "@/service/dataDictionary_api";

export default {
  name: "departmentsPlatform",
  data(){
    return{
      spinning:false,
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      searchName:'',
      addShow:false,
      editShow:false,
      addForm:{},
      editForm:{},
      rules:{
        title: [
          { required: true, message: '请输入科室名称', trigger: 'blur' }],
      },
      columns:[
        {
          title: '科室名称',
          dataIndex: 'title',
          width:'25%'
        },
        {
          title: '科室介绍',
          dataIndex: 'intro',
          width:'20%'
        },
        {
          title: '创建时间',
          dataIndex: 'created_time',
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' },
        },
      ],
      InfoTable:[],
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      departmentId:0,
      subShow:false,
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"注册科室管理")
  },
  mounted() {
    this.getDepartmentList()
  },
  methods:{
    //获取注册科室列表
    async getDepartmentList(pageNum,name){
      this.spinning = true
      const Info =await getLoginDepartment(pageNum,name)
      if (Info.code === 0){
        this.InfoTable = Info.data.wySy
        this.pagination.total = Info.data.count
      }
      this.spinning = false
    },
    //分页跳转
    paginationChange(e){
      this.getDepartmentList(e,this.searchName)
    },
    //搜索
    searchOk(){
      this.getDepartmentList('',this.searchName)
    },
    searchEnter(){
      this.searchOk()
    },
    //新增按钮
    addBtn(){
      this.addShow = true
      this.subShow = false
    },
    //新增确认
   async addOk(){
      this.$refs.add.validate(async valid =>{
        if (valid){
          if (this.subShow === false){
            this.form = {
              title : this.addForm.title,
              intro : this.addForm.intro
            }
          }else {
            this.form = {
              title : this.addForm.title,
              intro : this.addForm.intro,
              parent_id : this.departmentId
            }
          }
          const Info =await postLoginDepartment(this.form)
          if (Info.code === 0 ){
            this.$message.success('添加成功')
            await this.getDepartmentList(this.pagination.current)
            this.$refs.add.resetFields();
            this.addForm = {}
            this.addShow= false;
          }
        } else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //新增子科室
    addSub(id){
      this.departmentId = id
      this.addShow = true
      this.subShow = true
    },
    editBtn(row){
      this.editForm = JSON.parse(JSON.stringify(row))
      this.editShow = true
    },
    //修改子科室
    editSub(row){
      this.editForm = JSON.parse(JSON.stringify(row))
      this.editShow = true
    },
    //修改确认
    async editOk(){
      this.$refs.edit.validate(async valid =>{
        if (valid){
          const data = {
            title : this.editForm.title,
            intro : this.editForm.intro
          }
          const Info =await putLoginDepartment(this.editForm.id,data)
          if (Info.code === 0 ){
            this.$message.success('修改成功')
            await this.getDepartmentList(this.pagination.current)
            this.$refs.edit.resetFields();
            this.editForm = {}
            this.editShow= false;
          }
        } else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //搜索重置
    searchReset(){
      this.getDepartmentList()
      this.searchName = ''
      this.pagination.current = 1
    },
    async delOffices(id) {
      const del = await delLoginDepartment(id)
      if (del.code === 0) {
        this.$message.success('删除成功')
        await this.getDepartmentList(this.pagination.current)
      } else {
        this.$message.error('删除失败')
      }
    },
    delSubOffices(id){
      this.delOffices(id)
    },
    cancel(){
      this.$message.warning('取消删除');
    }
  },
}
</script>

<style lang="scss" scoped>
.departmentsPlatform{
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);
  .search_Info{
    border-radius: 3px;
    background-color: white;
    height: 80px;
    width: 98%;
    margin: 0 auto;
    line-height: 80px;
    display: flex;
    padding: 0 15px 0 15px;
  }
  .InfoTable{
    height:100%;
    width: 98%;
    margin: 10px auto;
    padding: 15px;
    background-color: white;
  }
}
</style>
